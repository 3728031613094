"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getENSContract = getENSContract;
exports.getOracleContract = getOracleContract;
exports.getPermanentRegistrarContract = getPermanentRegistrarContract;
exports.getPermanentRegistrarControllerContract = getPermanentRegistrarControllerContract;
exports.getResolverContract = getResolverContract;
exports.getReverseRegistrarContract = getReverseRegistrarContract;
exports.getTestRegistrarContract = getTestRegistrarContract;

var _ethers = require("ethers");

var _ensContracts = require("@ensdomains/ens-contracts");

// import { abi as oldResolverContract } from '@ensdomains/contracts/abis/ens-022/PublicResolver.json'
// import { abi as dnsRegistrarContractOld } from '@ensdomains/contracts/abis/dnsregistrar/DNSRegistrar.json'
// import { abi as legacyAuctionRegistrarContract } from '@ensdomains/contracts/abis/ens/HashRegistrar'
// import { abi as deedContract } from '@ensdomains/contracts/abis/ens/Deed'
function getReverseRegistrarContract(_ref) {
  var address = _ref.address,
      provider = _ref.provider;
  return new _ethers.Contract(address, _ensContracts.ReverseRegistrar, provider);
}

function getResolverContract(_ref2) {
  var address = _ref2.address,
      provider = _ref2.provider;
  return new _ethers.Contract(address, _ensContracts.Resolver, provider);
} // function getOldResolverContract({ address, provider }) {
//   return new Contract(address, oldResolverContract, provider)
// }


function getENSContract(_ref3) {
  var address = _ref3.address,
      provider = _ref3.provider;
  return new _ethers.Contract(address, _ensContracts.ENS, provider);
}

function getTestRegistrarContract(_ref4) {
  var address = _ref4.address,
      provider = _ref4.provider;
  return new _ethers.Contract(address, _ensContracts.TestRegistrar, provider);
} // function getOldDnsRegistrarContract({ parentOwner, provider }) {
//   return new Contract(parentOwner, dnsRegistrarContractOld, provider)
// }
// function getDnsRegistrarContract({ parentOwner, provider }) {
//   return new Contract(parentOwner, dnsRegistrarContract, provider)
// }


function getPermanentRegistrarContract(_ref5) {
  var address = _ref5.address,
      provider = _ref5.provider;
  return new _ethers.Contract(address, _ensContracts.BaseRegistrarImplementation, provider);
}

function getPermanentRegistrarControllerContract(_ref6) {
  var address = _ref6.address,
      provider = _ref6.provider;
  return new _ethers.Contract(address, _ensContracts.NNSRegistrarControllerWithReservation, provider);
} // function getDeedContract({ address, provider }) {
//   return new Contract(address, deedContract, provider)
// }
// function getLegacyAuctionContract({ address, provider }) {
//   return new Contract(address, legacyAuctionRegistrarContract, provider)
// }
// function getBulkRenewalContract({ address, provider }) {
//   return new Contract(address, bulkRenewalContract, provider)
// }


function getOracleContract(_ref7) {
  var address = _ref7.address,
      provider = _ref7.provider;
  return new _ethers.Contract(address, _ensContracts.AggregatorInterface, provider);
}